import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import axios from "axios";
import { apiBaseURL } from "../../config";

function ViewShop() {
    const [data, setData] = useState([]);
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

    // Get Shop
    const getShop = async () => {
        try {
            if (agentLogin.role === "Admin") {
                const response = await axios.get(`${apiBaseURL}/user/fetchShopData`, { headers: { Authorization: agentLogin.authToken } });
                if (response.data.status === 200) {
                    setData(response.data.data);
                }
            } else if (agentLogin.role === "Agent") {
                const response = await axios.post(`${apiBaseURL}/user/fetchShopDataForAgent`, { email: agentLogin.email }, { headers: { Authorization: agentLogin.authToken } });
                if (response.data.status === 200) {
                    setData(response.data.data);
                }
            }
        } catch (error) {
            Swal.fire(`Something went wrong!`, "error");
        }
    };

    useEffect(() => {
        getShop();
    }, []);

    const handleDelete = async (rowData) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${apiBaseURL}/user/deleteShop/${rowData.id}`, { headers: { Authorization: agentLogin.authToken } });
                    if (response.data.status === 200) {
                        Swal.fire("Success", response.data.message, "success");
                        getShop();
                    }
                } catch (error) {
                    console.error("Error deleting shop:", error);
                    Swal.fire("Error", "Failed to delete shop", "error");
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Handle cancel action
                Swal.fire("Cancelled", "Shop deletion was cancelled", "info");
            }
        });
    };

    const handleEdit = (rowData) => {
        Swal.fire({
            title: "Edit Shop",
            html:
                `<input id="shopName" class="swal2-input" value="${rowData.shop_name}" placeholder="Shop Name">` +
                `<input id="shopPhoneNumber" class="swal2-input" value="${rowData.shop_phoneNumber}" placeholder="Shop Phone Number">` +
                `<input id="password" class="swal2-input" value="${rowData.password}" placeholder="Password">`,
            focusConfirm: false,
            preConfirm: () => {
                const shop_name = Swal.getPopup().querySelector("#shopName").value;
                const shop_phoneNumber = Swal.getPopup().querySelector("#shopPhoneNumber").value;
                const password = Swal.getPopup().querySelector("#password").value;

                // Validate the form fields
                if (!shop_name || !shop_phoneNumber || !password) {
                    Swal.showValidationMessage("Please fill in all the fields");
                    return false;
                }

                const newData = {
                    id: rowData.id,
                    shop_name,
                    shop_phoneNumber,
                    password,
                };

                updateShop(newData);
            },
        });
    };


    const updateShop = async (newData) => {
        try {
            const response = await axios.put(`${apiBaseURL}/user/editShop`, newData, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                Swal.fire("Success", response.data.message, "success");
                getShop();
            }
        } catch (error) {
            console.error("Error editing shop:", error);
            Swal.fire("Error", "Failed to edit shop", "error");
        }
    };

    const columns = [
        { title: "S.No.", editable: "never", render: (rowData) => rowData.tableData.id + 1 },
        { title: "Shop Name", field: "shop_name" },
        { title: "Shop Phone Number", field: "shop_phoneNumber" },
        { title: "Password", field: "password" },
        { title: "Point", field: "point" },
        {
            title: "Actions",
            render: (rowData) => (
                <div className="d-flex align-items-center">
                    <button className="btn bg-prime text-white mr-2" onClick={() => handleEdit(rowData)}>
                        Edit
                    </button>
                    <button className="btn btn-danger" onClick={() => handleDelete(rowData)}>
                        Delete
                    </button>
                </div>
            ),
            editable: "never",
        },
    ];

    return (
        <div className="card card-outline card-info">
            <MaterialTable
                title="Shop"
                data={data}
                columns={columns}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "bold",
                    },
                    rowStyle: {
                        fontFamily: "Arial, sans-serif",
                    },
                }}
            />
        </div>
    );
}

export default ViewShop;
