import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios"; // Don't forget to import axios
import PropTypes from "prop-types";
import {apiBaseURL} from "../../config";
import Swal from "sweetalert2";

const GameSettingLogin = ({setToken, setEmail}) => {
    const [loginStatus, setLoginStatus] = useState("");
    const [code, setCode] = useState("")
    // let apiBaseURL = "http://3.88.202.90:5000";
    const history = useHistory();
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));

    const handleSubmit = async (e) => {
        // Add async keyword here
        e.preventDefault();
        try {
            const response = await axios.post(
                `${apiBaseURL}/user/confirmSettingLogin`,
                {code},
                {headers: {Authorization: agentLogin.authToken}}
            );
            if (response.data.status === 200) {
                // setToken(response.data);
                // sessionStorage.setItem("loginEmail", useremail);
                // setLoginStatus(response.data.message);
                Swal.fire({
                    position: "top-end",
                    icon: "Success",
                    title: "Success",
                    text: "Login Success",
                    timer: 1700,
                });
                
                // Redirect to the "BallSettings" page
                // window.location.href = "/BallSettings";
                history.push("/BallSetting");
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "Oops...",
                    text: response.data.message,
                    timer: 1700,
                });
                setLoginStatus(response.data.message);
            }
        } catch (error) {
            console.log(error)
            console.error("Error during login:", error);
            setLoginStatus("An error occurred during login.");
        }
    };

    const handleRedirect = () => {
        history.push("/BallSetting");
    };

    // const handleChange = (name) => (e) => {
    //     setValues({...values, [name]: e.target.value});
    // };

    const formStyle = {
        maxWidth: "300px",
        margin: "auto",
        marginTop: "10px",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    };

    const inputStyle = {
        width: "100%",
        marginBottom: "15px",
        padding: "10px",
        boxSizing: "border-box",
    };

    const buttonStyle = {
        width: "100%",
        padding: "10px",
        backgroundColor: "#3498db",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
    };

    return (
        <div>
            <h2
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    padding: "50px",
                }}
            >
                Login Game Setting
            </h2>
            <form onSubmit={handleSubmit} style={formStyle}>
                <div>
                    <label htmlFor="useremail">Code:</label>
                    <input
                        type="text"
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Enter setting code here..."
                        style={inputStyle}
                    />
                </div>
                {/* <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={values.password}
            onChange={handleChange('password')}
            style={inputStyle}
          />
        </div> */}
                <button
                    className="bg-prime text-white"
                    type="submit"
                    style={buttonStyle}
                >
                    {/* <button className='bg-prime text-white' type="submit" style={buttonStyle} onClick={handleRedirect}> */}
                    Enter
                </button>
                <p style={{textAlign: "center", marginTop: "10px"}}>
                    {loginStatus}
                </p>
            </form>
        </div>
    );
};

GameSettingLogin.propTypes = {
    setToken: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
};

export default GameSettingLogin;
