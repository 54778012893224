import {Link} from "react-router-dom";
import React, {useState, useEffect} from "react";
import axios from "axios";
import {apiBaseURL} from "../../config";
import "./../../style/app.css";

function AppSidebar() {
    const [agentName, setAgentName] = useState("");
    const [selectedItem, setSelectedItem] = useState("");

    const handleItemClick = (item) => {
        setSelectedItem(item === selectedItem ? null : item);
    };

    useEffect(() => {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        if (!tokenData) {
            sessionStorage.removeItem("token");
            window.location.reload();
        }

        const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));
        if (agentLogin.role === "Admin") {
            setAgentName("Admin");
            document.getElementById("AgentPointHistoryByEmail").style.display = "none";
            document.getElementById("ShopPointHistoryByEmail").style.display = "none";
        } else if (agentLogin.role === "Agent") {
            document.getElementById("AddAgent").style.display = "none";
            document.getElementById("AssignAgent").style.display = "none";
            document.getElementById("ViewAgent").style.display = "none";
            document.getElementById("Creditagentpoint").style.display = "none";
            // document.getElementById("agentmanagement").style.display = "none";
            document.getElementById("AgentPointHistory").style.display = "none";
            document.getElementById("AddPlayer").style.display = "none";
            document.getElementById("point-report").style.display = "none";
            document.getElementById("add-user-credits").style.display = "none";
            document.getElementById("deduct-user-credits").style.display = "none";
            document.getElementById("transaction-user-report").style.display = "none";
            document.getElementById("changeAdminPassword").style.display = "none";
            document.getElementById("systemSetting").style.display = "none";
            document.getElementById("ShopPointHistoryByEmail").style.display = "none";
            fetchAgentName(agentLogin);
        } else if (agentLogin.role === "Shop") {
            document.getElementById("AddAgent").style.display = "none";
            document.getElementById("AssignAgent").style.display = "none";
            document.getElementById("ViewAgent").style.display = "none";
            document.getElementById("Creditagentpoint").style.display = "none";
            document.getElementById("agentmanagement").style.display = "none";
            document.getElementById("AgentPointHistory").style.display = "none";
            document.getElementById("AddShop").style.display = "none";
            document.getElementById("AssignShop").style.display = "none";
            document.getElementById("ViewShop").style.display = "none";
            document.getElementById("Creditshoppoint").style.display = "none";
            // document.getElementById("shopmanagement").style.display = "none";
            document.getElementById("ShopPointHistory").style.display = "none";
            document.getElementById("changeAdminPassword").style.display = "none";
            document.getElementById("GameSettings").style.display = "none";
            document.getElementById("systemSetting").style.display = "none";
            document.getElementById("GameSetting").style.display = "none";
            document.getElementById("AgentPointHistoryByEmail").style.display = "none";
            fetchAgentName(agentLogin);
        }
    }, []);

    const fetchAgentName = async (agentLogin) => {
        try {
            const response = await axios.post(
                `${apiBaseURL}/user/getAgentName`,
                {
                    agent_email: agentLogin.email,
                },
                {
                    headers: {
                        Authorization: agentLogin.authToken,
                    },
                }
            );
            if (response?.data?.status === 200) {
                const fetchedAgentName = response?.data?.agent_name;
                setAgentName(fetchedAgentName);
            }
        } catch (error) {
            console.error("Error fetching agent name:", error);
        }
    };

    return (
        <aside className="main-sidebar elevation-3">
            <a href="index3.html" className="brand-link text-decoration-none">
                <img
                    src="P1.png"
                    alt="Bubble Shooter"
                    className="brand-image rounded-0"
                />
                {/* <span className="brand-text text-white">
                    {agentName}
                </span> */}
            </a>

            <div className="sidebar" style={{overflow: "auto"}}>
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column sidebar__menu"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item menu-open">
                            <Link to="/" className="nav-link active">
                                <i className="nav-icon fa-solid fa-house-chimney"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>



                        <li
                            id="agentmanagement"
                            className={`sidebar-menu-item sidebar-dropdown ${
                                selectedItem === "agentmanagement" ? "isActive" : ""
                            }`}
                        >
                            <div
                                className="side-menu"
                                onClick={() => handleItemClick("agentmanagement")}
                            >
                                <i className="menu-icon las la-user-secret" />
                                <span className="menu-title">Agent Management</span>
                            </div>
                            <div className="sidebar-submenu">
                                <ul className="sidebar-ul-none">
                                    <li className="sidebar-menu-item" id="AddAgent">
                                        <Link to="/AddAgent" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Add Agent</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="AssignAgent">
                                        <Link className="nav-link" to="/AssignAgent">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Assign Agent</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="ViewAgent">
                                        <Link className="nav-link" to="/ViewAgent">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">View Agent</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="Creditagentpoint">
                                        <Link className="nav-link" to="/CreditAgentPoints">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Credit Agent Points</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="AgentPointHistory">
                                        <Link className="nav-link" to="/AgentHistory">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Agent Point History</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="AgentPointHistoryByEmail">
                                        <Link className="nav-link" to={`/AgentHistory/${JSON.parse(sessionStorage.getItem("loginEmail")).email}`}>
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Agent Point History</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            id="shopmanagement"
                            className={`sidebar-menu-item sidebar-dropdown ${
                                selectedItem === "shopmanagement" ? "isActive" : ""
                            }`}
                        >
                            <div
                                className="side-menu"
                                onClick={() => handleItemClick("shopmanagement")}
                            >
                                <i className="menu-icon las la-shopping-cart" />
                                <span className="menu-title">{" "}Shop Management</span>
                            </div>
                            <div className="sidebar-submenu">
                                <ul className="sidebar-ul-none">
                                    <li className="sidebar-menu-item" id="AddShop">
                                        <Link to="/AddShop" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Add Shop</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="AssignShop">
                                        <Link to="/AssignShop" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Assign Shop</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="ViewShop">
                                        <Link to="/ViewShop" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">View Shop</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="Creditshoppoint">
                                        <Link to="/CreditShopPoints" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Credit Shop Points</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="ShopPointHistory">
                                        <Link to="/ShopHistory" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Shop Point History</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="ShopPointHistoryByEmail">
                                        <Link to={`/ShopHistory/${JSON.parse(sessionStorage.getItem("loginEmail")).email}`} className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Shop Point History</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li
                            id="usermanagement"
                            className={`sidebar-menu-item sidebar-dropdown ${
                                selectedItem === "usermanagement" ? "isActive" : ""
                            }`}
                        >
                            <div
                                className="side-menu"
                                onClick={() => handleItemClick("usermanagement")}
                            >
                                <i className="menu-icon las la-users" />
                                <span className="menu-title">Users Management</span>
                            </div>
                            <div className="sidebar-submenu">
                                <ul className="sidebar-ul-none">
                                    <li className="sidebar-menu-item">
                                        <Link to="/PlayersList" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">{" "}View Players</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="AddPlayer">
                                        <Link className="nav-link" to="/AddnewPlayer">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Add Players</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li id="point-report" className={`sidebar-menu-item sidebar-dropdown ${selectedItem === "pointreport" ? "isActive" : ""}`}>
                            <div className="side-menu" onClick={() => handleItemClick("pointreport")}>
                                <i className="menu-icon las la-tasks" />
                                <span className="menu-title">Point Report</span>
                            </div>
                            <div className="sidebar-submenu">
                                <ul className="sidebar-ul-none">
                                    <li className="sidebar-menu-item" id="add-user-credits">
                                        <Link to="/PointTransfer" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Add Credits</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="deduct-user-credits">
                                        <Link to="/PointDeduct" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Deduct Credits</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-menu-item" id="transaction-user-report">
                                        <Link to="/Transactions" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Transaction Report</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li id="GameSetting" className={`sidebar-menu-item sidebar-dropdown ${selectedItem === "GameSetting" ? "isActive" : ""}`}>
                            <div className="side-menu" onClick={() => handleItemClick("GameSetting")}>
                                <i className="menu-icon las la-gamepad" />
                                <span className="menu-title">Game Settings</span>
                            </div>
                            <div className="sidebar-submenu">
                                <ul className="sidebar-ul-none">
                                    <li className="sidebar-menu-item" id="GameSettings">
                                        <Link to="/gameSettingLogin" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Game Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li id="systemSetting" className={`sidebar-menu-item sidebar-dropdown ${selectedItem === "systemsettings" ? "isActive" : ""}`}>
                            <div className="side-menu" onClick={() =>handleItemClick("systemsettings")}>
                                <i className="menu-icon las la-cog" />
                                <span className="menu-title">System Settings</span>
                            </div>
                            <div className="sidebar-submenu">
                                <ul className="sidebar-ul-none">
                                    <li className="sidebar-menu-item">
                                        <Link to="/AdminContact" className="nav-link">
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Admin Contact</span>
                                        </Link>
                                    </li>
                                    <li
                                        className="sidebar-menu-item"
                                        id="changeAdminPassword"
                                    >
                                        <Link to="/changepassword" className="nav-link" >
                                            <i className="menu-icon las la-dot-circle" />
                                            <span className="menu-title">Change Password</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        {/* <li className="nav-item">
              <Link to="/Playerpoint" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p> Player Winning Transaction Report</p>
              </Link>
            </li> */}
                        {/* <li className="nav-header" id="GameSetting">Game Settings</li>
            <li className="nav-item" id="GameSettings">
              <Link to="/BallSetting" className="nav-link">
                <i className="fa-solid fa-users nav-icon" />
                <p> Game Settings</p>
              </Link>
            </li> */}
                        {/* 
            <li className="nav-header">Test:--------</li>
            <li className="nav-item">
              <Link to="/PlayersHistory" className="nav-link">
                <i className="fa-solid fa-users nav-icon" />
                <p> I/O Test</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/PlayersList" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Payout Test</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/ViewAgent" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Counter Test</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/AddnewPlayer" className="nav-link">
                <i className="fa-solid fa-users nav-icon" />
                <p> Sound Test</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/GamePlayHistory" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Screen Test</p>
              </Link>
            </li> */}
                        {/* 
            <li className="nav-header">Records:--------</li>
            <li className="nav-item">
              <Link to="/userupi" className="nav-link">
                <i className="fas fa-address-book nav-icon"></i>
                <p>Total Data</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/WheelOfForturneGamePlayHistory" className="nav-link">
                <i className="fas fa-address-book nav-icon"></i>
                <p>Current & Last Data</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/PokerGamePlayHistory" className="nav-link">
                <i className="fas fa-address-book nav-icon"></i>
                <p>Daily Data</p>
              </Link>
            </li>{" "}
            <li className="nav-item">
              <Link to="/TigerVsElephantGamePlayHistory" className="nav-link">
                <i className="fas fa-address-book nav-icon"></i>
                <p>Weekly Data</p>
              </Link>
            </li>{" "}
            <li className="nav-item">
              <Link to="/LuckyBallGamePlayHistory" className="nav-link">
                <i className="fas fa-address-book nav-icon"></i>
                <p>Monthly Data</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/LuckyBallGamePlayHistory" className="nav-link">
                <i className="fas fa-address-book nav-icon"></i>
                <p>Clear & Periodical Data</p>
              </Link>
            </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
}

export default AppSidebar;
