import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { apiBaseURL } from "../../config";

const CreditShopPoints = () => {
    // let apiBaseURL = "http://3.88.202.90:5000";

    const [selectedShop, setSelectedShop] = useState("");
    const [shop, setShop] = useState([]);
    const [values, setValues] = useState({
        point: "",
    });
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

    const fetchShop = async () => {
        try {
            if (agentLogin.role === "Admin") {
                const response = await axios.get(`${apiBaseURL}/user/fetchShops`, { headers: { Authorization: agentLogin.authToken } });
                setShop(response.data);
            } else if (agentLogin.role === "Agent") {
                const response = await axios.post(`${apiBaseURL}/user/fetchShopDataForAgent`, { email: agentLogin.email }, { headers: { Authorization: agentLogin.authToken } });
                const res = response.data.data;
                const _res = res?.map((shop) => shop.shop_email);
                setShop(_res);
            }
        } catch (error) {
            console.error("Error fetching shop:", error);
        }
    };

    useEffect(() => {
        fetchShop();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { point } = values;
        let data;
        if (agentLogin.role === "Admin") {
            data = {
                admin_email: agentLogin.email,
                shop_email: selectedShop,
                addAmount: parseInt(point),
            };
        } else {
            data = {
                shop_email: selectedShop,
                addAmount: parseInt(point),
                agent_email: agentLogin.email
            }
        }

        try {
            const response = await axios.post(`${apiBaseURL}/user/shopCreditPoints`, data, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                setValues({
                    point: "",
                });
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Point transferred",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "Oops...",
                    text: `${response.data.message}!`,
                    showConfirmButton: false,
                    timer: 1700,
                });
            }
        } catch (error) {
            console.error("Error transferring points:", error);
            Swal.fire("Something went wrong!", "error");
        }
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                    <div className="card card-outline card-warning">
                        <div className="card-header header-grey">
                            <h3 className="card-title text-white my-2">
                                <span className="me-3"><i className="fa-solid fa-arrow-right-arrow-left fa-2" /></span>
                                Transfer Point
                            </h3>
                        </div>
                        <div className="card-body">
                            <form method="post" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="shop_email">Shop Email *</label>
                                            <select
                                                id="shopemail"
                                                className="form-control"
                                                value={selectedShop}
                                                onChange={(e) => setSelectedShop(e.target.value)}
                                            >
                                                <option value="">Select Shop</option>
                                                {shop?.map((shopEmail) => (
                                                    <option key={shopEmail} value={shopEmail}>
                                                        {shopEmail}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="point">Amount To Transfer *</label>
                                            <input
                                                type="number"
                                                value={values.point}
                                                onChange={handleChange}
                                                name="point"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group row">
                                            <div className="col-sm-9">
                                                <div className="form-group row">
                                                    <div className="col-sm-4">
                                                        <button
                                                            type="button"
                                                            className="btn bg-prime text-white form-control"
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <button
                                                            type="submit"
                                                            className="btn bg-success text-white form-control"
                                                        >
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditShopPoints;
