import React from "react";
import {Link} from "react-router-dom";
import "./../../style/app.css";

function AppHeader() {
    const sessionData = JSON.parse(sessionStorage.getItem("token"));

    const logoutUser = async () => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loginEmail");
        window.location.href = "/";
    };

    return (
        <nav className="main-header navbar navbar-expand">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-widget="pushmenu"
                        href="#"
                        role="button"
                    >
                        <i className="fas fa-bars text-black fs-20"></i>
                    </a>
                </li>
            </ul>

            <ul className="navbar-nav ml-auto">
                <span className="brand-text d-flex align-items-center text-black">
                    {sessionData.user.username}
                </span>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-widget="fullscreen"
                        href="#"
                        role="button"
                    >
                        <i className="fas fa-expand-arrows-alt text-black fs-20"></i>
                    </a>
                </li>
                <li className="nav-item pr-4">
                    <Link
                        className="nav-link"
                        onClick={() => logoutUser()}
                        data-toggle="dropdown"
                        to="/"
                    >
                        <span className="text-black">
                            {" "}
                            <i className="fas fa-sign-out-alt"></i> Logout
                        </span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
}
export default AppHeader;
