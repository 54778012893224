import React, { useState, useEffect } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { apiBaseURL } from "../../config";
import { useParams } from "react-router-dom";

const ShopPointHistory = () => {
    const [pointHistory, setPointHistory] = useState([]);
    // let apiBaseURL = "http://3.88.202.90:5000";
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))
    const { email } = useParams();

    const fetchShopPointHistory = async () => {
        try {
            if (email) {
                const response = await axios.get(
                    `${apiBaseURL}/user/fetchShopPointHistoryByEmail/${email}`, { headers: { Authorization: agentLogin.authToken } }
                );
                setPointHistory(response.data.data);
                return;
            }
            if (agentLogin.role === "Admin") {
                const response = await axios.get(
                    `${apiBaseURL}/user/fetchShopPointHistory`, { headers: { Authorization: agentLogin.authToken } }
                );
                setPointHistory(response.data.data);
            } else if (agentLogin.role === "Agent") {
                const response = await axios.post(
                    `${apiBaseURL}/user/fetchShopPointHistoryForAgent`, { email: agentLogin.email }, { headers: { Authorization: agentLogin.authToken } }
                );
                setPointHistory(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching shop point history:", error);
        }
    };

    useEffect(() => {
        fetchShopPointHistory();
    }, []);

    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
    };

    const columns = [
        // { title: 'ID', field: 'id' },
        { title: "Sr No", render: (rowData) => rowData.tableData.id + 1 },
        { title: "Shop Email", field: "shop_email" },
        { title: "Responder Email", field: "sender_email" },
        { title: "Point", field: "point" },
        {
            title: "Date",
            field: "date",
            render: (rowData) => {
                const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(rowData.date));
                return formattedDate;
            },
        },
    ];

    return (
        <MaterialTable
            title="Shop Point History"
            columns={columns}
            data={pointHistory}
            options={{
                actionsColumnIndex: -1,
                sorting: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                headerStyle: {
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: "bold",
                },
                rowStyle: {
                    fontFamily: "Arial, sans-serif",
                },
            }}
        />
    );
};

export default ShopPointHistory;
