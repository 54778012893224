import React from 'react'
import "./../../style/app.css";

export default function AppFooter() {
  return (
    <>
    <aside className="control-sidebar"></aside>

        <footer className="main-footer bg-white">
        <strong>
            Copyright &copy; 2022-2023{" "}
            <a href=" " className='text-black'>Lucky Firework. </a>
        </strong>
        All rights reserved. Benno Team Gaming
        <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> V100FA
        </div>
        </footer>
    </>
  )
}
