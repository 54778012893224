// Import necessary dependencies
import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import { apiBaseURL } from "../../config";

// API base URL
// let apiBaseURL = "http://3.88.202.90:5000";

// Functional component for PlayersList
function PlayersList() {
    // State hooks
    const [data, setData] = useState([]);
    const [players, setPlayers] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));

    // Function to fetch players data from the server
    const getPlayers = async () => {

        try {
            // Fetch players data based on agent or admin login
            const response =
                agentLogin.role === "Admin"
                    ? await axios.get(`${apiBaseURL}/user/getPlayer`, { headers: { Authorization: agentLogin.authToken } })
                    : agentLogin.role === "Agent" ? await axios.post(`${apiBaseURL}/user/getAgentPlayer`, {
                        email: agentLogin.email,
                    }, { headers: { Authorization: agentLogin.authToken } })
                        : await axios.post(`${apiBaseURL}/user/getShopPlayer`, {
                            email: agentLogin.email,
                        }, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                // Map the response data to the required format and set it in the state
                const result = response.data.data?.filter((item) => item.email !== "admin@admin.com")?.map((item) => ({
                    ...item,
                    isBlocked: item.user_status === 1,
                }));
                setData(result);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Function to handle user deletion
    const handleDelete = async (userId) => {
        // Display confirmation dialog using SweetAlert2
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Make a request to delete the user
                    const response = await axios.delete(`${apiBaseURL}/user/deleteUser`, {
                        data: { userId }, headers: { Authorization: agentLogin.authToken }
                    });

                    if (response.data.status === 200) {
                        // If deletion is successful, show success message and refresh the players
                        Swal.fire("Success", response.data.message, "success");
                        await getPlayers();
                    }
                } catch (error) {
                    console.error("Error deleting user:", error);
                    // Show error message if deletion fails
                    Swal.fire("Error", "Failed to delete user", "error");
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Show info message if deletion is cancelled
                Swal.fire("Cancelled", "User deletion was cancelled", "info");
            }
        });
    };

    // Function to handle input changes for editing
    const handleInputChange = (e, rowData) => {
        const { name, value } = e.target;
        // Update the data state with the changed input value
        const updatedData = data?.map((item) =>
            item.user_id === rowData.user_id ? { ...item, [name]: value } : item
        );
        setData(updatedData);
    };

    // Columns configuration for MaterialTable
    const columns = [
        { title: "Sr No.", render: (rowData) => rowData.tableData.id + 1 },
        { title: "User Id", field: "user_id", editable: "never" },
        {
            title: "Phone number",
            field: "phone_number",
            render: (rowData) =>
                editingRow === rowData.user_id ? (
                    <input
                        type="text"
                        name="phone_number"
                        value={rowData.phone_number}
                        onChange={(e) => handleInputChange(e, rowData)}
                        style={{ width: "100px" }} // Set a fixed width for the input field
                    />
                ) : (
                    rowData.phone_number
                ),
        },
        { title: "Credits", field: "point", editable: "never" },
        { title: "IP", field: "ip", editable: "never" },
        {
            title: "Password",
            field: "password",
            render: (rowData) =>
                editingRow === rowData.user_id ? (
                    <input
                        type="password"
                        name="password"
                        value={rowData.password}
                        onChange={(e) => handleInputChange(e, rowData)}
                        style={{ width: "100px" }}
                    />
                ) : (
                    rowData.password
                ),
        },
        {
            title: "Block/Unblock",
            render: (rowData) => (
                <button
                    className={`btn ${rowData.isBlocked ? "btn-danger" : "btn-success"}`}
                    onClick={() => handleBlockUnblock(rowData)}
                >
                    {rowData.isBlocked ? "Unblock" : "Block"}
                </button>
            ),
            editable: "never",
        },
        {
            title: "Actions",
            render: (rowData) =>
                editingRow === rowData.user_id ? (
                    <div>
                        <button
                            className="btn btn-success"
                            onClick={() => saveRowChanges(rowData)}
                        >
                            Save
                        </button>
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        <button
                            className="btn bg-prime text-white mr-2"
                            onClick={() => setEditingRow(rowData.user_id)}
                        >
                            Edit
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(rowData.user_id)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            editable: "never",
        },
    ];

    // Function to handle blocking/unblocking a user
    const handleBlockUnblock = async (rowData) => {
        try {
            // Toggle the block status
            await axios.put(`${apiBaseURL}/user/block/${rowData.user_id}`, {
                user_id: rowData.user_id,
                block: !rowData.isBlocked,
            }, { headers: { Authorization: agentLogin.authToken } });

            // Check the updated block status using the isUserBlocked API
            const updatedIsBlockedResponse = await axios.post(
                `${apiBaseURL}/user/isUserBlocked`,
                {
                    user_id: rowData.user_id,
                }, { headers: { Authorization: agentLogin.authToken } }
            );

            const isUserBlocked = updatedIsBlockedResponse.data.isUserBlocked;

            // Update the rowData object and force a re-render
            setData(
                data?.map((player) =>
                    player.user_id === rowData.user_id
                        ? { ...player, isBlocked: isUserBlocked }
                        : player
                )
            );
        } catch (error) {
            console.error("Error blocking/unblocking user:", error);
        }
    };


    // Function to save changes after editing
    const saveRowChanges = async (rowData) => {
        setEditingRow(null);
        await handleEdit(rowData);
    };

    // Function to handle user edits
    const handleEdit = async (newData) => {
        try {
            // Make a request to edit the user
            const response = await axios.put(`${apiBaseURL}/user/editUser`, {
                userId: newData.user_id,
                phone_number: newData.phone_number,
                password: newData.password,
            }, { headers: { Authorization: agentLogin.authToken } });

            if (response.data.status === 200) {
                // Show success message and refresh the players
                Swal.fire("Success", response.data.message, "success");
                getPlayers();
            }
        } catch (error) {
            console.error("Error editing user:", error);
            // Show error message if editing fails
            Swal.fire("Error", "Failed to edit user", "error");
        }
    };

    // useEffect to fetch players on component mount
    useEffect(() => {
        getPlayers();
    }, []);

    // Return the JSX for rendering
    return (
        <div className="card card-outline card-info">
            <MaterialTable
                title="Players List"
                data={data}
                columns={columns}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "bold",
                    },
                    rowStyle: {
                        fontFamily: "Arial, sans-serif",
                    },
                }}
            />
        </div>
    );
}

// Export the PlayersList component
export default PlayersList;
