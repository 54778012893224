import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import { apiBaseURL } from "../../config";
import axios from "axios";

const TransactionReport = () => {
  const [transactionData, setTransactionData] = useState(null);
  const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        let endpoint = `${apiBaseURL}/user/transactions`;
        let response;
        if (agentLogin.role === "Agent") {
          endpoint = `${apiBaseURL}/user/getTransactionReport`;
          response = await axios.post(endpoint,{ agent_email: agentLogin.email }, {
            headers: { Authorization: agentLogin.authToken },
          });
        } else if (agentLogin.role === "Shop") {
          endpoint = `${apiBaseURL}/user/getTransactionReportForShop`;
          response = await axios.post(endpoint, { shop_email: agentLogin.email }, {
            headers: { Authorization: agentLogin.authToken },
          });
        } else {
          response = await axios.get(endpoint, {
            headers: { Authorization: agentLogin.authToken }
          });
        }
        const data = await response.data;

        const reversedData = {
          credited: data.credited.reverse(),
          debited: data.debited.reverse(),
        };
        setTransactionData(reversedData);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, []);

  const formatTimestamp = (timestamp) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(timestamp));

    // return moment(timestamp).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const tableStyle = {
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    padding: "16px",
  };

  const headerStyle = {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  };

  const rowStyle = (rowData, index) => {
    if (index % 2 === 0) {
      return { backgroundColor: "#f9f9f9" };
    }
    return {};
  };

  return (
    <div>
      {transactionData ? (
        <div style={tableStyle}>
          <h4>Credited Transactions:</h4>
          <MaterialTable
            columns={[
              { title: "User ID", field: "user_id" },
              { title: "Responder Email", field: "sender_email" },
              { title: "Points Credited", field: "point_credited" },
              {
                title: "Timestamp",
                field: "timestamp",
                render: (rowData) => formatTimestamp(rowData.timestamp),
              },
            ]}
            data={transactionData.credited}
            title=""
            options={{
              search: true,
              pageSize: 10,
              headerStyle,
              rowStyle,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#fff",
                color: "#000",
                fontWeight: "bold",
              },
              rowStyle: {
                fontFamily: "Arial, sans-serif",
              },
            }}
          />

          <h4 className="mt-4">Debited Transactions:</h4>
          <MaterialTable
            columns={[
              { title: "User ID", field: "user_id" },
              { title: "Responder Email", field: "sender_email" },
              { title: "Points Debited", field: "point_debited" },
              {
                title: "Timestamp",
                field: "timestamp",
                render: (rowData) => formatTimestamp(rowData.timestamp),
              },
            ]}
            data={transactionData.debited}
            title=""
            options={{
              search: true,
              pageSize: 10,
              headerStyle,
              rowStyle,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#fff",
                color: "#000",
                fontWeight: "bold",
              },
              rowStyle: {
                fontFamily: "Arial, sans-serif",
              },
            }}
          />
        </div>
      ) : (
        <p>Loading transaction data...</p>
      )}
    </div>
  );
};

export default TransactionReport;
