import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";
import { apiBaseURL } from "../../config";

function AddNewShop() {
    // let apiBaseURL = "http://3.88.202.90:5000";
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

    const sessionData = JSON.parse(sessionStorage.getItem("token"));
    const [values, setValues] = useState({
        shop_name: "",
        shop_phoneNumber: "",
        password: "",
        shop_email: "",
    });

    const [iscreated, setiscreated] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { shop_name, shop_phoneNumber, password, shop_email } = values; // Include shop_email in the request
        let payload;
        let url;
        if (agentLogin.role === "Admin") {
            payload = {
                shop_name,
                shop_phoneNumber,
                password,
                shop_email, // Include shop_email in the request
            };
            url = `${apiBaseURL}/auth/addshopbyadmin`
        } else if (agentLogin.role === "Agent") {
            payload = {
                shop_name,
                shop_phoneNumber,
                password,
                shop_email, // Include shop_email in the request
                agent_email: sessionData.user.email,
                agent_phoneNumber: sessionData.user.phone
            };
            url = `${apiBaseURL}/auth/addshopbyagent`
        }
        await axios({
            method: "post",
            url: url,
            data: payload,
            headers: { Authorization: agentLogin.authToken },
        })
            .then(function (response) {
                setiscreated((pre) => !pre);
                if (response.data.status === 200) {
                    setValues({
                        shop_name: "",
                        shop_phoneNumber: "",
                        password: "",
                        shop_email: "", // Reset shop_email value
                    });
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: `${response.data.message} !`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    if (response.data.status == 401) {
                        sessionStorage.removeItem("token");
                        window.location.reload();
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "Oops...",
                            text: `${response.data.message} !`,
                            showConfirmButton: false,
                            timer: 1700,
                        });
                    }
                }
            })
            .catch(function (error) {
                Swal.fire(`Something went wrong!`, "error");
            });
    };

    useEffect(() => {
        // Your existing code here
    }, [iscreated]);

    const handleChange = (name) => (e) => {
        setValues({ ...values, [name]: e.target.value });
    };

    return (
        <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                <div className="card card-outline card-info">
                    <div className="card-header header-grey">
                        <h3 className="card-title d-flex align-items-center text-white my-1">
                            <span className="me-3"><i className="las la-user-tie" /></span> Add New Shop
                        </h3>
                    </div>
                    <div className="card-body">
                        <form method="post" onSubmit={handleSubmit}>
                            <div className="form-group row">
                                <label htmlFor="inputShopName" className="col-sm-3 col-form-label">
                                    Shop Name
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        name="shop_name"
                                        value={values.shop_name}
                                        onChange={handleChange("shop_name")}
                                        className="inputfield form-control"
                                        placeholder="Enter shop name"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputPhoneNumber" className="col-sm-3 col-form-label">
                                    Phone Number
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        name="shop_phoneNumber"
                                        value={values.shop_phoneNumber}
                                        onChange={handleChange("shop_phoneNumber")}
                                        className="inputfield form-control"
                                        placeholder="Enter phone number"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputPassword" className="col-sm-3 col-form-label">
                                    Enter Password
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange("password")}
                                        className="inputfield form-control"
                                        placeholder="***********"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputShopEmail" className="col-sm-3 col-form-label">
                                    Shop Email
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="email"
                                        name="shop_email"
                                        value={values.shop_email}
                                        onChange={handleChange("shop_email")}
                                        className="inputfield form-control"
                                        placeholder="Enter shop email"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-3 "></div>
                                <div className="col-sm-9">
                                    <div className="form-group row">
                                        <div className="col-sm-3 ">
                                            <button className="bg-prime text-white form-control">Reset</button>
                                        </div>
                                        <div className="col-sm-3 ">
                                            <button type="submit" className="bg-success text-white form-control"> {/* Changed 'onSubmit' to 'submit' */}
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddNewShop;
