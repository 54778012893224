import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { apiBaseURL } from "../../config";

const CreditAgentPoints = () => {
    // let apiBaseURL = "http://3.88.202.90:5000";
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

    const [selectedAgent, setSelectedAgent] = useState("");
    const [agents, setAgents] = useState([]);
    const [values, setValues] = useState({
        point: "",
    });

    const fetchAgents = async () => {
        try {
            const response = await axios.get(`${apiBaseURL}/user/fetchAgents`, { headers: { Authorization: agentLogin.authToken } });
            setAgents(response.data);
        } catch (error) {
            console.error("Error fetching agents:", error);
        }
    };

    useEffect(() => {
        fetchAgents();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { point } = values;
        const data = {
            sender_email: agentLogin.email,
            agent_email: selectedAgent,
            addAmount: parseInt(point, 10),
        };

        try {
            const response = await axios.post(`${apiBaseURL}/user/AgentcreditPoints`, data, { headers: { Authorization: agentLogin.authToken } });
            if (response.status === 200) {
                setValues({
                    point: "",
                });
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Point transferred",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "Oops...",
                    text: `${response.data.message}!`,
                    showConfirmButton: false,
                    timer: 1700,
                });
            }
        } catch (error) {
            console.error("Error transferring points:", error);
            Swal.fire("Something went wrong!", "error");
        }
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                    <div className="card card-outline card-warning">
                        <div className="card-header header-grey">
                            <h3 className="card-title text-white my-2">
                                <span className="me-3"><i className="fa-solid fa-arrow-right-arrow-left fa-2" /></span>
                                Transfer Point
                            </h3>
                        </div>
                        <div className="card-body">
                            <form method="post" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="agent_email">Agent Email *</label>
                                            <select
                                                id="agentemail"
                                                className="form-control"
                                                value={selectedAgent}
                                                onChange={(e) => setSelectedAgent(e.target.value)}
                                            >
                                                <option value="">Select Agent</option>
                                                {agents?.map((agentEmail) => (
                                                    <option key={agentEmail} value={agentEmail}>
                                                        {agentEmail}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="point">Amount To Transfer *</label>
                                            <input
                                                type="number"
                                                value={values.point}
                                                onChange={handleChange}
                                                name="point"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group row">
                                            <div className="col-sm-9">
                                                <div className="form-group row">
                                                    <div className="col-sm-4">
                                                        <button
                                                            type="button"
                                                            className="btn bg-prime text-white form-control"
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <button
                                                            type="submit"
                                                            className="btn bg-success text-white form-control"
                                                        >
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditAgentPoints;
