import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import { apiBaseURL } from "../../config";

function AssignShop() {
    // const apiBaseURL = "http://3.88.202.90:5000";
    const [shopData, setShopData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [selectedShop, setSelectedShop] = useState({});
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))
    useEffect(() => {
        if (shopData?.length > 0 && userData?.length > 0) {
            const shopObj = {}
            userData?.map((user) => {
                const _shop = shopData.find((shop) => shop.shop_email === user.shop_email);
                if (_shop) {
                    shopObj[user.user_id] = _shop.shop_name
                }
            })
            setSelectedShop((prev) => ({
                ...prev,
                ...shopObj
            }))
        }
    }, [shopData, userData])

    const fetchShopData = async () => {
        try {
            if (agentLogin.role === "Admin") {
                const response = await axios.get(`${apiBaseURL}/user/fetchShopData`, { headers: { Authorization: agentLogin.authToken } });
                if (response.data.status === 200) {
                    const res = response.data.data;
                    setShopData(res);
                }
            } else if (agentLogin.role === "Agent") {
                const response = await axios.post(`${apiBaseURL}/user/fetchShopDataForAgent`, { email: agentLogin.email }, { headers: { Authorization: agentLogin.authToken } });
                if (response.data.status === 200) {
                    const res = response.data.data;
                    setShopData(res);
                }
            }
        } catch (error) {
            console.error("Error fetching shop data:", error);
        }
    };

    const fetchUserData = async () => {
        try {
            if (agentLogin.role === "Admin") {
                const response = await axios.get(`${apiBaseURL}/user/getPlayer`, { headers: { Authorization: agentLogin.authToken } });
                if (response.data.status === 200) {
                    setUserData(response.data.data);
                }
            } else if (agentLogin.role === "Agent") {
                const response = await axios.post(`${apiBaseURL}/user/getAgentPlayer`, { email: agentLogin.email }, { headers: { Authorization: agentLogin.authToken } });
                if (response.data.status === 200) {
                    setUserData(response.data.data);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const fetchShopPhoneNumber = async (shop_name) => {
        try {
            const response = await axios.post(`${apiBaseURL}/user/fetchShopPhoneNumber`, {
                shop_name: shop_name,
            }, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                const phoneNumber = response.data.data;
                return phoneNumber;
            }
        } catch (error) {
            console.error("Error fetching shop phone number:", error);
        }
        return null;
    };


    const updateShopPhoneNumber = async (user_id, shop_phoneNumber, shop_email) => {
        try {
            const response = await axios.put(
                `${apiBaseURL}/user/updateShopPhoneNumber`,
                {
                    user_id: user_id,
                    shop_phoneNumber: shop_phoneNumber,
                    shop_email: shop_email,
                }, { headers: { Authorization: agentLogin.authToken } }
            );
            if (response.data.status === 200) {
                Swal.fire("Success", "Shop details updated successfully", "success");
            } else {
                Swal.fire("Error", "Failed to update shop details", "error");
            }
        } catch (error) {
            console.error("Error updating shop details:", error);
        }
    };


    const handleShopNameChange = (user_id, shopName) => {
        setSelectedShop((prevSelectedShop) => ({
            ...prevSelectedShop,
            [user_id]: shopName,
        }));
    };

    const handleSave = async (user_id) => {
        const shopName = selectedShop[user_id];
        if (shopName !== "") {
            try {
                const shopDetails = await fetchShopPhoneNumber(shopName);
                if (shopDetails) {
                    const { shop_phoneNumber, shop_email } = shopDetails;
                    await updateShopPhoneNumber(user_id, shop_phoneNumber, shop_email);
                    window.location.reload();
                } else {
                    Swal.fire("Error", "Shop details not found", "error");
                }
            } catch (error) {
                console.error("Error updating shop details:", error);
            }
        } else {
            Swal.fire("Error", "Please select an shop", "error");
        }
    };



    useEffect(() => {
        fetchShopData();
        fetchUserData();
    }, []);

    return (
        <div>
            <MaterialTable
                title="Assign Shop"
                columns={[
                    { title: "User ID", field: "user_id" },
                    { title: "Shop Phone Number", field: "shop_phoneNumber" },
                    {
                        title: "Shop Name",
                        field: "shop_name",
                        render: (rowData) => (
                            <select
                                value={selectedShop[rowData.user_id] || ""}
                                onChange={(e) => handleShopNameChange(rowData.user_id, e.target.value)}
                            >
                                <option value="">Select Shop</option>
                                {shopData?.map((shop) => (
                                    <option key={shop.id} value={shop.shop_name}>
                                        {shop.shop_name}
                                    </option>
                                ))}
                            </select>

                        ),
                    },
                    {
                        title: "Save",
                        field: "save",
                        render: (rowData) => (
                            <button
                                onClick={() => handleSave(rowData.user_id)}
                                className="btn btn-success"
                            >
                                Save
                            </button>
                        ),
                    },
                ]}
                data={userData}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "bold",
                    },
                    rowStyle: {
                        fontFamily: "Arial, sans-serif",
                    },
                }}
            />
        </div>
    );
}

export default AssignShop;
