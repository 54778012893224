// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body{
    background: #2a3042;
}
.login-center{
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

/* ......logo.......  */
.boxStyle{
    margin: 20px 0 0 0;
}
.boxStyle :hover{
    transform: scale(1.1); 
}
img{
    border-radius: 15px;
} 
/* ........loginBody.......  */
.paperStyle{
    padding: 20px;
    height: 55vh;
    width: 100%;
    margin: 20px auto 0 auto;
}
.login-logo{
    width: 70%;
    height: auto;
}
.bg-prime{
    background: #556ee6 !important;
}`, "",{"version":3,"sources":["webpack://./src/style/Login.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;AACpB;;AAEA,uBAAuB;AACvB;IACI,kBAAkB;AACtB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB;AACA,8BAA8B;AAC9B;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,wBAAwB;AAC5B;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,8BAA8B;AAClC","sourcesContent":["\r\nbody{\r\n    background: #2a3042;\r\n}\r\n.login-center{\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n    min-height: 100vh;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: relative;\r\n    z-index: 1;\r\n    overflow: hidden;\r\n}\r\n\r\n/* ......logo.......  */\r\n.boxStyle{\r\n    margin: 20px 0 0 0;\r\n}\r\n.boxStyle :hover{\r\n    transform: scale(1.1); \r\n}\r\nimg{\r\n    border-radius: 15px;\r\n} \r\n/* ........loginBody.......  */\r\n.paperStyle{\r\n    padding: 20px;\r\n    height: 55vh;\r\n    width: 100%;\r\n    margin: 20px auto 0 auto;\r\n}\r\n.login-logo{\r\n    width: 70%;\r\n    height: auto;\r\n}\r\n.bg-prime{\r\n    background: #556ee6 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
