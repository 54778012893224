import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import '../../style/Contact.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import axios from "axios";
import { apiBaseURL } from "../../config";
// import { authToken } from "../../authToken";
const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

function PlayerHistory() {
    const [data, setData] = useState([]);
    //get Agents
    const gameHistory = async () => {
        await axios
            .get(`${apiBaseURL}/api/games/playersHistory`, { headers: { Authorization: agentLogin.authToken } })
            .then(function (response) {
                if (response.data.status === 200) {
                    setData(response.data.data);
                }
            })
            .catch(function (error) {
                // history.push("/login")
            });
    };


    //get Agents

    const columns = [
        { title: "Serial No", render: rowData => rowData.tableData.id + 1 },
        { title: "Start", render: rowData => rowData.username ? rowData.username : "Guest" },
        { title: "Take", field: "player_id" },
        { title: "Test", field: "game_name" },
        { title: "Services", render: rowData => (rowData.total_bet && rowData.total_bet > 0) ? rowData.total_bet : '0542' },
        { title: "UP", field: "" },
        { title: "Down", field: "" },
        { title: "Coin", field: "percent" },
        { title: "HPSW", field: "status" },
        { title: "Ticket-sw", field: "status" }

    ];

    useEffect(() => {
        gameHistory();
        // fetch("/api/transaction")
        //     .then((response) => response.json())
        //     .then(json => setData(json))
    }, []);
    return (
        <>
            <div className="card card-outline card-info">
                <MaterialTable
                    title="I/O Test"
                    data={data}
                    columns={columns}
                    options={{ actionsColumnIndex: -1 }}
                />
            </div>
        </>
    )
}
export default PlayerHistory
