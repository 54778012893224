import React, {useState, useEffect} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import {apiBaseURL} from "../../config";

const AdminContactForm = () => {
    // let apiBaseURL = "http://3.88.202.90:5000";
    const sessionData = JSON.parse(sessionStorage.getItem("loginEmail"));
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));

    const [agent_phoneNumber, setAgentPhoneNumber] = useState("");
    const [whatsapp_number, setWhatsappNumber] = useState("");
    const [email, setEmail] = useState("");
    const [disabledFlag, setDisabledFlag] = useState(
        sessionData.role !== "Admin"
    );
    // const [contactData, setContactData] = useState([]);
    useEffect(() => {
        fetchAgentContact();
    }, []);

    const fetchAgentContact = async () => {
        try {
            const response = await axios.get(
                `${apiBaseURL}/user/fetchContact`,
                {headers: {Authorization: agentLogin.authToken}}
            );
            if (response.data.length > 0) {
                const contactDetails = response.data[0];
                setAgentPhoneNumber(contactDetails.agent_phoneNumber);
                setWhatsappNumber(contactDetails.whatsapp_number);
                setEmail(contactDetails.email);
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred while fetching agent contact details",
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (disabledFlag) {
            return;
        }
        try {
            const response = await axios.post(
                `${apiBaseURL}/auth/addAdminContact`,
                {
                    agent_phoneNumber,
                    whatsapp_number,
                    email,
                },
                {headers: {Authorization: agentLogin.authToken}}
            );
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Contact details have been updated successfully",
            });
            setAgentPhoneNumber("");
            setWhatsappNumber("");
            setEmail("");
            fetchAgentContact(); // Fetch updated contact data after successful submission
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred while updating contact details",
            });
        }
    };

    const columns = [
        {title: "Agent Phone Number", field: "agent_phoneNumber"},
        {title: "WhatsApp Number", field: "whatsapp_number"},
        {title: "Email", field: "email"},
    ];

    const styles = {
        form: {
            maxWidth: "800px",
            margin: "auto",
            padding: "20px",
            borderRadius: "5px",
            backgroundColor: "#f5f5f5",
        },
        heading: {
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333",
        },
        inputContainer: {
            marginBottom: "20px",
        },
        input: {
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            borderRadius: "5px",
            border: "1px solid #ddd",
            fontSize: "16px",
        },
        label: {
            marginBottom: "5px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333",
        },
        button: {
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "none",
            backgroundColor: "#007bff",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
        },
    };

    return (
        <>
            <form onSubmit={handleSubmit} style={styles.form}>
                <h2 style={styles.heading}>Admin Contact Form</h2>
                <div style={styles.inputContainer}>
                    {/* <div>
                        <label htmlFor="agentPhoneNumber" style={styles.label}>
                            Agent Phone Number
                        </label>
                        <input
                            type="text"
                            id="agentPhoneNumber"
                            placeholder="Agent Phone Number"
                            value={agent_phoneNumber}
                            onChange={(e) => setAgentPhoneNumber(e.target.value)}
                            style={styles.input}
                            disabled={disabledFlag}
                        />
                    </div> */}
                    <div>
                        <label htmlFor="whatsappNumber" style={styles.label}>
                            WhatsApp Number
                        </label>
                        <input
                            type="text"
                            id="whatsappNumber"
                            placeholder="WhatsApp Number"
                            value={whatsapp_number}
                            onChange={(e) => setWhatsappNumber(e.target.value)}
                            style={styles.input}
                            disabled={disabledFlag}
                        />
                    </div>
                    <div>
                        <label htmlFor="email" style={styles.label}>
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={styles.input}
                            disabled={disabledFlag}
                        />
                    </div>
                </div>
                <button
                    className="bg-prime text-white"
                    type="submit"
                    style={styles.button}
                >
                    Submit
                </button>
            </form>

            {/* <MaterialTable
        title="Agent Contact Details"
        columns={columns}
        data={contactData}
        options={{
          search: false,
          paging: false,
          toolbar: false,
        }}
      /> */}
        </>
    );
};

export default AdminContactForm;
