import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { apiBaseURL } from "../../config";
function WithdrawRequest() {
  // let apiBaseURL = "http://3.88.202.90:5000";
  const [data, setData] = useState([]);
  const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

  //Update Status

  //get Agents
  const verifyBankAccount = async (txnId, bankID, userId) => {
    Swal.fire({
      title: `Are you sure? Want to Approved`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Approved it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        let updateData = {
          user_id: userId,
          bank_detail_id: bankID,
          txn_id: txnId,
        };
        axios
          .put(`${apiBaseURL}/payouts/VerifyBankAccountStatus`, updateData,{headers: { Authorization: agentLogin.authToken }})
          .then(function (response) {
            if (response.data.status === 200) {
              getReports();
              Swal.fire(
                `Verified!`,
                `User status have been ${response.data.message}`,
                "success"
              );
            } else {
              Swal.fire(`${response.data.message}!`, "error");
            }
          })
          .catch(function (error) {
            Swal.fire(`Something Went wrong!`, "error");
          });
      }
    });
  };
  //get Agents
  const acceptPayouts = async (txnId, bankID, userId) => {
    Swal.fire({
      title: `Are you sure? Want to Accept...`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Accept it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        let updateData = {
          user_id: userId,
          bank_detail_id: bankID,
          txn_id: txnId,
        };
        axios
          .put(`${apiBaseURL}/payouts/acceptPayouts`, updateData,{headers: { Authorization: agentLogin.authToken }})
          .then(function (response) {
            if (response.data.status === 200) {
              getReports();
              Swal.fire(
                `Accepted!`,
                `User request have been ${response.data.message}`,
                "success"
              );
            } else {
              Swal.fire(`${response.data.message}!`, "error");
            }
          })
          .catch(function (error) {
            Swal.fire(`Something Went wrong!`, "error");
          });
      }
    });
  };
  const columns = [
    { title: "Sr_No", render: (rowData) => rowData.tableData.id + 1 },

   
    { title: "Coin Rate", field: "amount" },
    { title: "Game Rate", field: "email" },
     { title: "Auto Start", field: "txn_id",filtering:true },
     { title: "Demo Sound", field: "is_request_mode",filtering:true },
     { title: "Ticket Rate", field: ""},
     { title: "Mercy Ticket", field: "" },
   
   /*  {
      title: "Date",
      render: (rowData) =>
        rowData.txn_date
          ? moment(rowData.txn_date).format("YYYY-M-D h:mm:ss")
          : "Not login yet",
      filtering: true,
    },
  */ ];
  //get Agents
  const getReports = async () => {
    await axios({
      method: "GET",
      url: `${apiBaseURL}/user/getwithdrawRequest`,
      headers: { Authorization: agentLogin.authToken }
      // data: user,
      // headers: {"Authorization" : `Bearer ${authToken}`}
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    getReports();
  }, []);
  //get Agents
  const changeStatus = async (distributor, status, message) => {
   };

  //get Agents
  const deleteUser = async (distributor) => {
    
  };
  return (
    <>
      <div className="card card-outline card-info">
        <MaterialTable
          title="System Adjustmemt"
          data={data}
          columns={columns}
          
        />
      </div>
    </>
  );
}
export default WithdrawRequest;
