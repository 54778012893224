import React, {useState} from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";
import {apiBaseURL} from "../../config";
import { useEffect } from "react";

function ChangePassword() {
    // let apiBaseURL  = "http://3.88.202.90:5000"
    const [values, setValues] = useState({
        newpassword: "",
        confirmPassword: "",
    });
    const tokenData = JSON.parse(sessionStorage.getItem("token"));
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));

    useEffect(() => {
      getSettingCode();
    }, [])

    const getSettingCode = async () => {
        try {
            const response = await axios.get(
                `${apiBaseURL}/user/getSettingCode`,
                {headers: {Authorization: agentLogin.authToken}}
            );
            const code = response.data.data.code;
            setSettingCode(code)
            if (response.data.length > 0) {
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred while fetching agent contact details",
            });
        }
    }
    

    const [settingCode, setSettingCode] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {newpassword, confirmPassword} = values;
        const user = {
            email: "admin@admin.com",
            old_password: newpassword,
            new_password: confirmPassword,
        };
        await axios({
            method: "POST",
            url: `${apiBaseURL}/auth/resetPassword`,
            data: user,
            headers: {Authorization: agentLogin.authToken},
        })
            .then(function (response) {
                if (response.data.status === 200) {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Success! Thank you..",
                        text: "Password updated",
                        timer: 1700,
                    });
                    setValues({
                        newpassword: "",
                        confirmPassword: "",
                    });
                } else {
                    if (response.data.status == 401) {
                        Swal.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "Oops...",
                            text: `${response.data.message} !`,
                            showConfirmButton: false,
                            timer: 1700,
                        });
                        //sessionStorage.removeItem("token");
                        //window.location.reload();
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "Oops...",
                            text: `${response.data.message} !`,
                            showConfirmButton: false,
                            timer: 1700,
                        });
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    //
    const handleChange = (name) => (e) => {
        setValues({...values, [name]: e.target.value});
    };

    const handleUpdateCode = async (e) => {
        e.preventDefault();
        if (settingCode === "") {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Oops...",
                text: "Please Enter Setting Code!",
                timer: 1700,
            });
         return;   
        }
        const user = {
            code: settingCode,
        };
        await axios({
            method: "POST",
            url: `${apiBaseURL}/user/updateSettingCode`,
            data: user,
            headers: {Authorization: agentLogin.authToken},
        })
            .then(function (response) {
                if (response.data.status === 200) {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Success! Thank you..",
                        text: "Setting Code updated",
                        timer: 1700,
                    });
                } else {
                    if (response.data.status == 401) {
                        Swal.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "Oops...",
                            text: `${response.data.message} !`,
                            showConfirmButton: false,
                            timer: 1700,
                        });
                        //sessionStorage.removeItem("token");
                        //window.location.reload();
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "Oops...",
                            text: `${response.data.message} !`,
                            showConfirmButton: false,
                            timer: 1700,
                        });
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                    <div className="card card-outline card-info">
                        <div className="card-header header-grey">
                            <h3 className="card-title d-flex align-items-center text-white my-1">
                                <span className="me-3">
                                    <i className="las la-lock" />
                                </span>{" "}
                                Change Password
                            </h3>
                        </div>
                        <div className="card-body">
                            <form method="post" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="newpassword">
                                                Old Password *
                                            </label>
                                            <input
                                                type="password"
                                                value={values.newpassword}
                                                onChange={handleChange("newpassword")}
                                                name="newpassword"
                                                className="inputfield form-control"
                                                placeholder="*******"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword">
                                                New Password *
                                            </label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange(
                                                    "confirmPassword"
                                                )}
                                                className="inputfield form-control"
                                                placeholder="*******"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="bg-prime text-white form-control"
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                    <div className="card card-outline card-info">
                        <div className="card-header header-grey">
                            <h3 className="card-title d-flex align-items-center text-white my-1">
                                {/* <span className="me-3">
                                    <i className="las la-lock" />
                                </span>{" "} */}
                                Change Setting Code
                            </h3>
                        </div>
                        <div className="card-body">
                            <form method="post" onSubmit={handleUpdateCode}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="newpassword">
                                                Setting Code
                                            </label>
                                            <input
                                                type="text"
                                                value={settingCode}
                                                onChange={(e) => setSettingCode(e.target.value)}
                                                name="settingCode"
                                                className="inputfield form-control"
                                                placeholder="Enter Setting Code"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="bg-prime text-white form-control"
                                            >
                                                Update Code
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ChangePassword;
