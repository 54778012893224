import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { apiBaseURL } from "../../config";

const PointDeduct = () => {
  // let apiBaseURL = "http://3.88.202.90:5000";

  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);
  const [values, setValues] = useState({
    point: "",
  });

  const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));
  const fetchUsers = async () => {

    try {
      let response;

      if (agentLogin.role === "Admin") {
        response = await axios.get(`${apiBaseURL}/user/fetchUsers`,{headers: { Authorization: agentLogin.authToken }});
      } else if (agentLogin.role === "Agent") {
        response = await axios.post(`${apiBaseURL}/user/fetchPlayersByAgent`, {
          agent_email: agentLogin.email,
        },{headers: { Authorization: agentLogin.authToken }});
      }
      else {
        response = await axios.post(`${apiBaseURL}/user/fetchPlayersByShop`, {
          shop_email: agentLogin.email,
        },{headers: { Authorization: agentLogin.authToken }});
      }

      if (response.data.status === 200) {
        const fetchedUsers = response.data.players; 
        setUsers(fetchedUsers);
      } else {
        console.error("Error fetching users:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchAgentCredits = async () => {
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));
    try {
      let response;
      if (agentLogin.role === "Agent") {
        response = await axios.post(`${apiBaseURL}/user/fetchAgentCredits`, {
          agent_email: agentLogin.email,
        },{headers: { Authorization: agentLogin.authToken }});
      }
      if (agentLogin.role === "Shop") {
        response = await axios.post(`${apiBaseURL}/user/fetchShopCredits`, {
          shop_email: agentLogin.email,
        },{headers: { Authorization: agentLogin.authToken }});
      }

      if (response.data.status === 200) {
        return response.data.credits;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error fetching agent credits:", error);
      return 0;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { point } = values;
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail")); 
    let data;
    let role;
    if (agentLogin.role === "Admin") {
      data = {
        user_id: selectedUser,
        admin_email: agentLogin.email,
        deductAmount: parseInt(point),
      };
      role = "Admin";
    } else {
      data = {
        user_id: selectedUser,
        deductAmount: parseInt(point),
        shop_email: agentLogin.email, 
      }
      role = "Shop";
    }

    try {
      const response = await axios.post(`${apiBaseURL}/user/debitAndCreditPoints`, data, {headers: { Authorization: agentLogin.authToken }});
      if (response.data.status === 200) {
        setValues({
          point: "",
        });

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `Points deducted`,
          // title: `Point deducted and added to ${role}'s wallet`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Oops...",
          text: `${response.data.message}!`,
          showConfirmButton: false,
          timer: 1700,
        });
      }
    } catch (error) {
      console.error("Error deducting and crediting points:", error);
      Swal.fire("Something went wrong!", "error");
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
          <div className="card card-outline card-warning">
            <div className="card-header header-grey">
              <h3 className="card-title text-white my-2">
                <span className="me-3"><i className="fa-solid fa-arrow-right-arrow-left fa-2" /></span>
                Deduct Point
              </h3>
            </div>
            <div className="card-body">
              <form method="post" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="user_id">user_id *</label>
                      <select
                        id="useremail"
                        className="form-control"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                      >
                        <option value="">Select User</option>
                        {users?.map((user_id) => (
                          <option key={user_id} value={user_id}>
                            {user_id}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="point">Amount To Deduct *</label>
                      <input
                        type="number"
                        value={values.point}
                        onChange={handleChange}
                        name="point"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group row">
                      <div className="col-sm-9">
                        <div className="form-group row">
                          <div className="col-sm-4 ">
                            <button className="btn bg-prime text-white form-control">
                              Reset
                            </button>
                          </div>
                          <div className="col-sm-4 ">
                            <button
                              type="submit"
                              className="btn bg-success text-white form-control"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointDeduct;
