import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {apiBaseURL} from "../../config";

const userDataObj = {
    userCount: 0,
    shopCount: 0,
    agentCount: 0,
    points: 0
}

const Home = () => {
    // let apiBaseURL = "http://3.88.202.90:5000";
    let [dateTime, setDateTime] = useState();
    let [todayDate, setTodayDate] = useState();
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));

    useEffect(() => {
        const timer = setInterval(() => {
            let current = new Date();
            let date = moment(current).format("dddd DD MMMM, YYYY");
            const times = `${current.getHours()}:${
                current.getMinutes() + 1
            }:${current.getSeconds()}`;
            setDateTime(times);
            setTodayDate(date);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const [winngPoints, setWinngPoints] = useState({});
    const [userData, setUserData] = useState(userDataObj);
    const [destriData, setDestriData] = useState([]);
    const [agentPoint, setAgentPoint] = useState(0);
    useEffect(() => {
        if (agentLogin) {
            if (agentLogin.role === "Agent") {
                document.getElementById("agentTab").style.display = "none"; 
            } else if (agentLogin.role === "Shop") {
              document.getElementById("agentTab").style.display = "none"; 
              document.getElementById("shopTab").style.display = "none"; 
            }
        }
    }, [agentLogin])

    const getPlayers = async () => {
        const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));
        try {
            if (agentLogin?.role === "Admin") {
                const response = await axios.get(
                    `${apiBaseURL}/user/getDashboardCountForAdmin`,
                    {headers: {Authorization: agentLogin.authToken}}
                );
                if (response.data.status === 200) {
                    console.log(response.data.data)
                    setUserData(response.data.data);
                }
            } else if (agentLogin?.role === "Agent") {
                const response = await axios.post(
                    `${apiBaseURL}/user/getDashboardCountForAgent`,
                    {
                        email: agentLogin.email,
                    },
                    {headers: {Authorization: agentLogin.authToken}}
                );
                if (response.data.status === 200) {
                    const result = response.data.data;
                    setUserData(result);
                }
            } else {
                const response = await axios.post(
                    `${apiBaseURL}/user/getDashboardCountForShop`,
                    {
                        email: agentLogin.email,
                    },
                    {headers: {Authorization: agentLogin.authToken}}
                );
                if (response.data.status === 200) {
                    const result = response.data.data;
                    setUserData(result);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    //get Agents and Points data
    const getData = async () => {
        await axios
            .get(`${apiBaseURL}/api/user/agents`, {
                headers: {Authorization: agentLogin.authToken},
            })
            .then(function (response) {
                if (response.data.status === 200) {
                    setDestriData(response.data.data);
                }
            })
            .catch(function (error) {});

        await axios
            .get(`${apiBaseURL}/api/games/winnigPoints`, {
                headers: {Authorization: agentLogin.authToken},
            })
            .then(function (response) {
                if (response.data.status === 200) {
                    setWinngPoints(response.data.data[0]);
                }
            })
            .catch(function (error) {});

        const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));
        try {
            const response = await axios.post(
                `${apiBaseURL}/user/fetchAgentCredits`,
                {
                    agent_email: agentLogin.email,
                },
                {headers: {Authorization: agentLogin.authToken}}
            );
            if (response.data.status === 200) {
                setAgentPoint(response.data.credits);
            } else {
                setAgentPoint(0);
            }
        } catch (error) {
            console.error("Error fetching agent credits:", error);
        }
    };

    useEffect(() => {
        // getData();
        getPlayers();
    }, []);

    return (
        <>
            <div className="content-header pt-0">
                <div className="d-flex align-items-center justify-content-between">
                    <h1 className="fs-5 m-0">Dashboard</h1>
                    <div className="d-flex align-items-center">
                        <h1 className="m-0 fs-6 text-red mr-2">{dateTime}</h1>
                        <h1 className="m-0 font-weight-bold fs-6 text-red">
                            {todayDate}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
          <div className="rounded-3 bg-prime p-3 h-100">
            <div className="d-flex align-items-center justify-content-between">
              <i className="fas fa-users text-white fs-40"></i>
              <div className="d-block text-end">
                <Link
                  to="/PlayersList"
                  className="font-weight-bold text-white fs-6 text-decoration-none"
                >
                  Players
                </Link>
                <p className="mb-0 text-white fs-3">
                  {userData.length < 10
                    ? `0${userData.length}`
                    : userData.length}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
          <div className="rounded-3 bg-success p-3 h-100">
            <div className="d-flex align-items-center justify-content-between">
              <i className="fas fa-coins text-white fs-40"></i>
              <div className="d-block text-end">
                <p className="mb-0 text-white fs-6">Turn Over</p>
                <p className="mb-0 text-white fs-6">
                  {agentPoint ? agentPoint.toLocaleString() : "No credits..."}
                </p>
              </div>
            </div>
          </div>
        </div> */}
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" id="agentTab">
                    <div className="rounded-3 bg-white p-3 h-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-block">
                                <Link to="/ViewAgent" className="font-weight-bold text-black fs-6 text-decoration-none">
                                    Total Agents
                                </Link>
                                <p className="mb-0 text-black fs-3">{userData.agentCount}</p>
                            </div>
                            <div className="icon-box">
                                <i className="fas fa-user text-primary fs-20"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" id="shopTab">
                    <div className="rounded-3 bg-white p-3 h-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-block">
                                <Link to="/ViewShop" className="font-weight-bold text-black fs-6 text-decoration-none">
                                    Total Shops
                                </Link>
                                <p className="mb-0 text-black fs-3">{userData.shopCount}</p>
                            </div>
                            <div className="icon-box">
                                <i className="fas fa-user text-primary fs-20"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" id="playerTab">
                    <div className="rounded-3 bg-white p-3 h-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-block">
                                <Link to="/PlayersList" className="font-weight-bold text-black fs-6 text-decoration-none">
                                    Total Players
                                </Link>
                                <p className="mb-0 text-black fs-3">{userData.userCount}</p>
                            </div>
                            <div className="icon-box">
                                <i className="fas fa-user text-primary fs-20"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {agentLogin?.role !== "Admin" && <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="rounded-3 bg-white p-3 h-100">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-block">
                                <div className="font-weight-bold text-black fs-6 text-decoration-none">Points</div>
                                <p className="mb-0 text-black fs-6">
                                    {userData.points}
                                </p>
                            </div>
                            <div className="icon-box">
                                <i className="fas fa-coins text-primary fs-20"></i>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    );
};

export default Home;
