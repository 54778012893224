import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import { apiBaseURL } from "../../config";

function AssignAgent() {
    // const apiBaseURL = "http://3.88.202.90:5000";
    const [agentData, setAgentData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState({});
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))


    useEffect(() => {
        if (agentData?.length > 0 && userData?.length > 0) {
            const agentObj = {}
            userData?.map((user) => {
                const _agent = agentData.find((agent) => agent.agent_email === user.agent_email);
                if (_agent) {
                    agentObj[user.id] = _agent.agent_name
                }
            })
            setSelectedAgents((prev) => ({
                ...prev,
                ...agentObj
            }))
        }
    }, [agentData, userData])


    const fetchAgentData = async () => {
        try {
            const response = await axios.get(`${apiBaseURL}/user/fetchAgentData`, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                const res = response.data.data;
                setAgentData(res);
            }
        } catch (error) {
            console.error("Error fetching agent data:", error);
        }
    };

    const fetchShopData = async () => {
        try {
            const response = await axios.get(`${apiBaseURL}/user/fetchShopData`, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                // const res = response.data.data;
                // const _res = res.map((item) => ({[item.id]: item.agent_name}))
                // console.log(res)
                setUserData(response.data.data);

            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const fetchAgentPhoneNumber = async (agent_name) => {
        try {
            const response = await axios.post(`${apiBaseURL}/user/fetchAgentPhoneNumber`, {
                agent_name: agent_name,
            }, { headers: { Authorization: agentLogin.authToken } });
            if (response.data.status === 200) {
                const phoneNumber = response.data.data;
                return phoneNumber;
            }
        } catch (error) {
            console.error("Error fetching agent phone number:", error);
        }
        return null;
    };


    const updateAgentPhoneNumber = async (id, agent_phoneNumber, agent_email) => {
        try {
            const response = await axios.put(
                `${apiBaseURL}/user/updateAgentPhoneNumberForShop`,
                {
                    id: id,
                    agent_phoneNumber: agent_phoneNumber,
                    agent_email: agent_email,
                }, { headers: { Authorization: agentLogin.authToken } }
            );
            if (response.data.status === 200) {
                Swal.fire("Success", "Agent details updated successfully", "success");
            } else {
                Swal.fire("Error", "Failed to update agent details", "error");
            }
        } catch (error) {
            console.error("Error updating agent details:", error);
        }
    };


    const handleAgentNameChange = (id, agentName) => {
        setSelectedAgents((prevSelectedAgents) => ({
            ...prevSelectedAgents,
            [id]: agentName,
        }));
    };

    const handleSave = async (id) => {
        const agentName = selectedAgents[id];
        if (agentName !== "") {
            try {
                const agentDetails = await fetchAgentPhoneNumber(agentName);
                if (agentDetails) {
                    const { agent_phoneNumber, agent_email } = agentDetails;
                    await updateAgentPhoneNumber(id, agent_phoneNumber, agent_email);
                    window.location.reload();
                } else {
                    Swal.fire("Error", "Agent details not found", "error");
                }
            } catch (error) {
                console.error("Error updating agent details:", error);
            }
        } else {
            Swal.fire("Error", "Please select an agent", "error");
        }
    };



    useEffect(() => {
        fetchAgentData();
        fetchShopData();
    }, []);

    return (
        <div>
            <MaterialTable
                title="Assign Agent"
                columns={[
                    { title: "User ID", field: "id" },
                    { title: "Shop Name", field: "shop_name" },
                    { title: "Agent Phone Number", field: "agent_phoneNumber" },
                    {
                        title: "Agent Name",
                        field: "agent_name",
                        render: (rowData) => (
                            <select
                                value={selectedAgents[rowData.id] || ""}
                                onChange={(e) => handleAgentNameChange(rowData.id, e.target.value)}
                            >
                                <option value="">Select Agent</option>
                                {agentData?.map((agent) => (
                                    <option key={agent.id} value={agent.agent_name}>
                                        {agent.agent_name}
                                    </option>
                                ))}
                            </select>

                        ),
                    },
                    {
                        title: "Save",
                        field: "save",
                        render: (rowData) => (
                            <button
                                onClick={() => handleSave(rowData.id)}
                                className="btn btn-success"
                            >
                                Save
                            </button>
                        ),
                    },
                ]}
                data={userData}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "bold",
                    },
                    rowStyle: {
                        fontFamily: "Arial, sans-serif",
                    },
                }}
            />
        </div>
    );
}

export default AssignAgent;
