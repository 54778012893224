import React, {useState, useEffect} from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";
import {apiBaseURL} from "../../config";

function AddNewPlayer() {
    //let apiBaseURL = "http://139.59.60.118:5000";
    //let apiBaseURL = "http://3.88.202.90:5000";
    // let apiBaseURL = "http://3.88.202.90:5000";
    const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"))

    const sessionData = sessionStorage.getItem("token");
    const [playerId, setPlayerId] = useState("");
    const [values, setValues] = useState({
        password: "",
        user_id: "",
        agent_email: "",
        agent_phoneNumber: "",
    });

    const [destriData, setDestriData] = useState("");
    const [iscreated, setiscreated] = useState(false);
    const [agent, setagent] = useState([]);
    const [agent2, setagent2] = useState([]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {password} = values;
        const agentLogin = JSON.parse(sessionStorage.getItem("loginEmail"));
        const agentData = JSON.parse(sessionStorage.getItem("token"));
        if (agentLogin.role === "Agent") {
            axios
                .post(`${apiBaseURL}/user/getAgentDetail`, {
                    email: agentLogin.email,
                },{headers: { Authorization: agentLogin.authToken }})
                .then((res) => {
                    setagent2(res.data.data);
                    const user = {
                        password,
                        agent_email: res.data.data[0].agent_email,
                        agent_phoneNumber: res.data.data[0].agent_phoneNumber,
                        user_id: playerId,
                        role_id: 4,
                    };
                    axios({
                        method: "post",
                        url: `${apiBaseURL}/auth/adduserbyadmin`,
                        data: user,
                        headers: {Authorization: agentLogin.authToken},
                    })
                        .then(function (response) {
                            setiscreated((pre) => !pre);
                            if (response.data.status === 200) {
                                setValues({
                                    password: "",
                                    username: "",
                                });
                                Swal.fire({
                                    position: "top-end",
                                    icon: "success",
                                    title: `${response.data.message} !`,
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } else {
                                if (response.data.status == 401) {
                                    sessionStorage.removeItem("token");
                                    window.location.reload();
                                } else {
                                    Swal.fire({
                                        position: "top-end",
                                        icon: "warning",
                                        title: "Oops...",
                                        text: `${response.data.message} !`,
                                        showConfirmButton: false,
                                        timer: 1700,
                                    });
                                }
                            }
                        })
                        .catch(function (error) {
                            Swal.fire(`Something went wrong!`, "error");
                        });
                })
                .catch((error) => {
                    console.error("err", error);
                });
        } else if (agentLogin.role === "Shop") {
            axios
                .post(`${apiBaseURL}/user/getShopDetail`, {
                    email: agentLogin.email,
                },{headers: { Authorization: agentLogin.authToken }})
                .then((res) => {
                    setagent2(res.data.data);
                    const user = {
                        password,
                        agent_email: res.data.data[0].shop_email,
                        agent_phoneNumber: res.data.data[0].shop_phoneNumber,
                        user_id: playerId,
                        role_id: 4,
                    };
                    axios({
                        method: "post",
                        url: `${apiBaseURL}/auth/adduserbyadmin`,
                        data: user,
                        headers: {Authorization: agentLogin.authToken},
                    })
                        .then(function (response) {
                            setiscreated((pre) => !pre);
                            if (response.data.status === 200) {
                                setValues({
                                    password: "",
                                    username: "",
                                });
                                Swal.fire({
                                    position: "top-end",
                                    icon: "success",
                                    title: `${response.data.message} !`,
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } else {
                                if (response.data.status == 401) {
                                    sessionStorage.removeItem("token");
                                    window.location.reload();
                                } else {
                                    Swal.fire({
                                        position: "top-end",
                                        icon: "warning",
                                        title: "Oops...",
                                        text: `${response.data.message} !`,
                                        showConfirmButton: false,
                                        timer: 1700,
                                    });
                                }
                            }
                        })
                        .catch(function (error) {
                            Swal.fire(`Something went wrong!`, "error");
                        });
                })
                .catch((error) => {
                    console.error("err", error);
                });
        } 
        else {
            const user = {
                password,
                agent_email: agentData.user.email,
                agent_phoneNumber: agentData.user.phone,
                user_id: playerId,
                role_id: 4,
            };
            axios({
                method: "post",
                url: `${apiBaseURL}/auth/adduserbyadmin`,
                data: user,
                headers: {Authorization: agentLogin.authToken},
            })
                .then(function (response) {
                    setiscreated((pre) => !pre);
                    if (response.data.status === 200) {
                        setValues({
                            password: "",
                            username: "",
                        });
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: `${response.data.message} !`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        if (response.data.status == 401) {
                            sessionStorage.removeItem("token");
                            window.location.reload();
                        } else {
                            Swal.fire({
                                position: "top-end",
                                icon: "warning",
                                title: "Oops...",
                                text: `${response.data.message} !`,
                                showConfirmButton: false,
                                timer: 1700,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    Swal.fire(`Something went wrong!`, "error");
                });
        }
    };

    //get Agents
    const getAgents = async () => {
        await axios
            .get(`${apiBaseURL}/user/getPlayerId`,{headers: { Authorization: agentLogin.authToken }})
            .then(function (response) {
                if (response.data.status === 200) {
                    setDestriData(response.data.data);
                    setPlayerId(response.data.data);
                }
            })
            .catch(function (error) {
                Swal.fire(`Something went wrong!`, "error");
            });
    };
    //

    const handleChange = (name) => (e) => {
        setValues({...values, [name]: e.target.value});
    };
    useEffect(() => {
        axios
            .get(`${apiBaseURL}/user/fetchAgentData`,{headers: { Authorization: agentLogin.authToken }})
            .then((res) => {
                setagent(res.data.data);
            })
            .catch((error) => {
                console.error("err", error);
            });

        getAgents();
    }, [iscreated]);

    return (
        <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                <div className="card card-outline card-info ">
                    <div className="card-header header-grey">
                        <h3 className="card-title d-flex align-items-center text-white my-1">
                            <span className="me-3"><i className="las la-user-tie" /></span> Add New
                            Player
                        </h3>
                    </div>
                    <div className="card-body">
                        <form method="post" onSubmit={handleSubmit} autocomplete="off">
                            <div className="form-group row">
                                <label
                                    htmlFor="staticusername"
                                    className="col-sm-3 col-form-label"
                                >
                                    Player Id
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        required
                                        autocomplete="off"
                                        className="inputfield"
                                        placeholder="Enter Player Id"
                                        value={playerId}
                                        onChange={(e) =>
                                            setPlayerId(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-sm-3 col-form-label"
                                >
                                    Enter Password
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="password"
                                        name="password"
                                        autocomplete="off"
                                        value={values.password}
                                        onChange={handleChange("password")}
                                        className="inputfield form-control"
                                        placeholder="Enter Password"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-3 "></div>
                                <div className="col-sm-9">
                                    <div className="form-group row">
                                        <div className="col-sm-3 ">
                                            <button type="reset" className="bg-prime text-white form-control">
                                                Reset
                                            </button>
                                        </div>
                                        <div className="col-sm-3 ">
                                            <button
                                                type="onSubmit"
                                                className="bg-success text-white form-control"
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddNewPlayer;
